import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './assets/tailwind.css'
import ru from './assets/locales/ru.json'
import en from './assets/locales/en.json'

//let locale = window.location.pathname.replace(/^\/[^/]+\/([^/]+).*/i, '$1');
//let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
//console.log(window.location.pathname);
//console.log('locale = '+locale);
//if (locale.length > 2) locale = 'ru';
const i18n = createI18n({
  //  locale: (locale.trim().length && locale !== "/") ? locale : 'ru',
    locale: 'ru',
    fallbackLocale: 'ru',
  //  globalInjection: true,
    messages: {
        ru,
        en
    },
});

createApp(App).use(i18n).mount('#app')

<script setup>

</script>

<template>
<div class="border-t-2 border-[#9EA2A8] border-l border-r rounded-tr-3xl rounded-tl-3xl h-[9px]"/>
</template>

<style scoped lang="scss">

</style>
<script setup>

</script>

<template>
    <footer class="flex mt-20 pb-12">
        <img v-if="$i18n.locale === 'ru'" src="@/assets/images/footer-logo.png">
        <img v-if="$i18n.locale === 'en'" src="@/assets/images/footer-logo-en.png">
        <div class="grow">&nbsp;</div>
        <div>© {{ new Date().getFullYear() }}</div>
    </footer>

</template>

<style scoped lang="scss">

</style>
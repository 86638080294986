<script setup>

import SeparatorBlue from "@/components/separator-blue.vue";
</script>

<template>
    <div>
        <separator-blue v-if="separator"/>
        <div class="flex mt-2">
            <div v-html="prefix" class="font-[500] text-[16px] leading-[21.6px] mr-6"/>
            <div>
                <h3 class="font-[600] text-[28px] leading-[33.6px]" v-html="title"/>
                <div class="font-[400] text-[16px] leading-[21.6px] mt-3" v-if="description" v-html="description"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['separator', 'prefix', 'title', 'description']
}
</script>

<style scoped lang="scss">

</style>
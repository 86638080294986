<template>
    <app-header/>
    <div class="container">
        <div class="grid grid-cols-2 mt-28 sm:grid-cols-1">
            <div class="font-[300] text-[42px] leading-[50.4px] sm:text-[32px] sm:leading-[38.4px] sm:mb-8" v-html="$t('about.left')"></div>
            <div>
                <div class="font-[400] text-[22px] leading-[29.7px]" v-html="$t('about.right.p1')"/>
                <div class="mt-6 font-[400] text-[16px] leading-[21.6px]" v-html="$t('about.right.p2')"/>
                <div class="mt-5 font-[400] text-[16px] leading-[21.6px]" v-html="$t('about.right.p3')"/>
            </div>
        </div>

        <h2 class="mt-28" id="products">{{ $t('products.title') }}</h2>
        <div class="grid grid-cols-2 mt-16 gap-10 sm:grid-cols-1">
            <product-info v-for="idx in 4"
                          :key="idx" :separator="true"
                          :prefix="$t('products.items['+(idx-1)+'].prefix')"
                          :title="$t('products.items['+(idx-1)+'].title')"
                          :description="$t('products.items['+(idx-1)+'].description')"
            />
        </div>

        <div class="grid-cols-3 grid mt-28 sm:hidden">
            <h2 v-html="$t('transaction.title')"/>
            <img v-if="$i18n.locale === 'ru'" src="@/assets/images/schema.png" alt="schema">
            <img v-if="$i18n.locale === 'en'" src="@/assets/images/schema-en.png" alt="schema">
            <div>&nbsp;</div>
        </div>
        <div class="grid-cols-3 grid mt-16 hidden sm:block">
            <h2 v-html="$t('transaction.title_sm')" class="mb-8"/>
            <img src="@/assets/images/schema.png" alt="schema">
            <div>&nbsp;</div>
        </div>

        <h2 class="mt-20 sm:mt-10" v-html="$t('account.title')"/>
        <separator-blue class="mt-10 sm:hidden"/>
        <div class="grid grid-cols-3 mt-5 gap-10 sm:grid-cols-1">
            <product-info v-for="idx in 3"
                          :key="idx"
                          :separator="false"
                          :prefix="$t('account.items['+(idx-1)+'].prefix')"
                          :title="$t('account.items['+(idx-1)+'].title')"
                          :description="$t('account.items['+(idx-1)+'].description')"
            />
        </div>

    </div>
    <div class="footer-bg">
        <div class="container">
            <div class="grid grid-cols-3 mt-32 gap-10 sm:grid-cols-1">
                <h2 v-html="$t('staff.title')"/>
                <div class="col-span-2">
                    <staff-info class="mb-10" v-for="idx in 3"
                                :key="idx"
                                :photo="photos[idx-1]"
                                :name="$t('staff.items['+(idx-1)+'].name')"
                                :position="$t('staff.items['+(idx-1)+'].position')"
                                :description="$t('staff.items['+(idx-1)+'].description')"
                    />

                </div>
            </div>

            <h2 id="reviews" class="mt-28 sm:mt-12" v-html="$t('reviews.title')"/>
            <div class="grid grid-cols-2 mt-16 bg-white rounded-lg p-12 sm:grid-cols-1 sm:p-0 sm:bg-transparent">
                <div class="pr-20 sm:pr-0 sm:py-8 sm:px-4 sm:bg-white sm:rounded-lg sm:mb-6" v-for="idx in 2" :key="idx">
                    <div v-html="$t('reviews.items['+(idx-1)+'].text')"/>
                    <div class="font-[400] text-[22px] leading-[29.7px] mt-10" v-html="$t('reviews.items['+(idx-1)+'].person')"/>
                </div>
            </div>

            <h2 id="contacts" class="mt-28" v-html="$t('contacts.title')"/>
            <div class="grid grid-cols-2 mt-8 gap-8 sm:grid-cols-1">
                <contact-card v-for="idx in 2"
                              :key="idx"
                              :description="$t('contacts.items['+(idx-1)+'].description')"
                              :phone="$t('contacts.items['+(idx-1)+'].phone')"
                              :email="$t('contacts.items['+(idx-1)+'].email')"
                              :person="$t('contacts.items['+(idx-1)+'].person')"
                              :position="$t('contacts.items['+(idx-1)+'].position')"
                />
            </div>

            <app-footer/>
        </div>
    </div>
</template>

<script>

import '@/assets/tailwind.css';
import AppHeader from "@/components/app-header.vue";
import ProductInfo from "@/components/product-info.vue";
import SeparatorBlue from "@/components/separator-blue.vue";
import StaffInfo from "@/components/staff-info.vue";
import ContactCard from "@/components/contact-card.vue";
import AppFooter from "@/components/app-footer.vue";

export default {
    name: 'App',
    components: {AppFooter, ContactCard, StaffInfo, SeparatorBlue, ProductInfo, AppHeader},

    data: () => ({
        'photos': [
            require('@/assets/images/p.kachur.png'),
            require('@/assets/images/n.kucherova.png'),
            require('@/assets/images/e.sergeev.png'),
        ]
    }),

    created() {
    },
}

</script>

<style lang="scss">

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    line-height: 22px;
    color: black;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    @media screen and (max-width: 575px) {
        font-size: 15.5px;
    }
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.footer-bg {
    background-image: url("@/assets/images/footer.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -200px;
    padding-top: 200px;
    @media screen and (max-width: 575px) {
        background-image: url("@/assets/images/footer-sm.png");
        background-position: bottom;
        margin-top: 0px;
        padding-top: 0px;
    }
}

h2 {
    font-weight: 300;
    font-size: 42px;
    line-height: 50.4px;
    @media screen and (max-width: 575px) {
        font-size: 32px;
        line-height: 38.4px;
    }
}

</style>

<script setup>

</script>

<template>
    <a
        class="text-[#0505054D] mr-[8px]"
        :class="{ 'text-[#000000]': (locale === $i18n.locale)}"
        v-for="locale in $i18n.availableLocales" :key="locale"
        @click="$i18n.locale = locale"
    >
        {{ locale.toUpperCase() }}
    </a>
</template>

<style scoped lang="scss">
a {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
}
</style>
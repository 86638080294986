<script setup>
import LocaleChanger from "@/components/locale-changer.vue";
</script>

<template>
    <div class="border-b border-[#05050526] h-[48px]">
        <div class="container flex items-center h-full">
            <img class="sm:block hidden" src="@/assets/images/menu-sm.png" alt="menu" @click="mobMenu = !mobMenu"/>
            <div class="flex-grow sm:block hidden"></div>
            <locale-changer/>
            <div class="flex-grow sm:hidden"></div>
            <button @click="goto('products')" class="sm:hidden">{{ $t('nav.products') }}</button>
            <button @click="goto('reviews')" class="ml-[52px] sm:hidden">{{ $t('nav.reviews') }}</button>
            <button @click="goto('contacts')" class="ml-[52px] sm:hidden">{{ $t('nav.contacts') }}</button>
        </div>
    </div>
    <div v-if="mobMenu" class="mob-menu sm:flex flex-col hidden border-b border-[#05050526] pl-4 py-4 transition-all items-start justify-between overflow-hidden" :class="mobMenu?'h-32':'h-0'">
        <button @click="goto('products')" class="block">{{ $t('nav.products') }}</button>
        <button @click="goto('reviews')" class="block">{{ $t('nav.reviews') }}</button>
        <button @click="goto('contacts')" class="block">{{ $t('nav.contacts') }}</button>
    </div>
</template>

<script>
export default {
    data: () => ({
        mobMenu: false
    }),
    methods: {
        goto(id) {
            let pos = document.getElementById(id).offsetTop;
            if (this.mobMenu) {
            this.mobMenu = false;
            pos -= 200;
            }
            window.scrollTo({
                top: pos,
                left: 0,
                behavior: "smooth",
            });
        }
    }
}
</script>

<style scoped lang="scss">
button {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
}

</style>
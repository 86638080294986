<script setup>

</script>

<template>
<div class="border-t-[2px] border-[#558BF1] border-l border-r rounded-tr-3xl rounded-tl-3xl h-[9px]"/>
</template>

<style scoped lang="scss">

</style>
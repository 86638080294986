<script setup>

import SeparatorGray from "@/components/separator-gray.vue";
</script>

<template>
    <div>
        <separator-gray/>
        <div class="font-[400] text-[22px] leading-[29.7px] mt-3" v-html="description"/>
        <a class="font-[600] text-[28px] leading-[33.6px] mt-8 text-white underline block" v-html="email" :href="'mailto:'+email" />
        <div class="font-[900] text-[42px] leading-[50.4px] sm:text-[28px] sm:mt-0 mt-2 text-white" v-html="phone"/>
        <div class="mt-4">{{person}}&emsp;|&emsp;{{position}}</div>
    </div>
</template>

<script>
export default {
    props: ['description', 'email', 'phone', 'person', 'position'],
}
</script>

<style scoped lang="scss">

</style>
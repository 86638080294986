<script setup>

</script>

<template>
    <div class="flex sm:flex-col">
        <img class="mr-8 sm:mr-0" :src="photo" alt="photo">
        <div>
            <div class="font-[900] text-[42px] leading-[50.4px] sm:font-[600] sm:text-[28px] sm:leading-[33.6px] sm:mt-6" v-html="name"/>
            <div class="font-[400] text-[22px] leading-[29.7px] mt-1 sm:text-[16px]" v-html="position"/>
            <div class="mt-4" v-html="description"/>
        </div>
    </div>
</template>

<script>
export default {
    props: ['photo', 'name', 'position', 'description'],
}
</script>

<style scoped lang="scss">
img {
    box-shadow: 0px 50px 30px -20px #00000033;
}
</style>